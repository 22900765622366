
import Card from '@/components/Card.vue';
import { defineComponent, PropType, ref } from 'vue'
export default defineComponent({
  components: {
    Card
  },
  setup(){
    const name = ref('Dashboard');

    return {
      name
    }
  }
 
});
