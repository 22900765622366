
import * as c3 from 'c3'
import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
import { Calendar } from 'v-calendar'
import { useStore } from 'vuex'
import axios from '@/axios'

export default defineComponent({
  components: {
    Calendar,
  },
  setup () {
    const store = useStore()
    const name = ref('Dashboard')
    const retailer_id = ref<string>('')
    const site_id = ref<string>('')
    const site_product_id = ref<string>('')
    const calendar = ref<any>()
    const day = ref<string>('')
    const isCalendarLoading = ref<boolean>(false)
    const isInfoLoading = ref<boolean>(false)
    const isAdmin = ref<boolean>(false)

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: 'Tank',
        dataIndex: 'tank',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: 'status'},
      },
    ]

    const getAllRetailers = () => {
      store.dispatch('Option/getAllRetailers')
    }
    const getAllRetailerSites = () => {
      store.dispatch('Option/getAllRetailerSites')
    }
    const getAllSitesPerRetailer = (payload: any) => {
      store.dispatch('Option/getAllSitesPerRetailer', payload)
    }
    const getAllSiteProduct = (payload: any) => {
      store.dispatch('Option/getAllSiteProduct', payload)
    }

    const onChange = (payload: any) => {
      getAllSitesPerRetailer({
        ...payload,
        selected_retailer: payload
      })
      site_id.value = ''
      day.value = ''
    }

    const convertTZ = (date) => {
      return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone: 'Asia/Manila'}))
    }

    const handleDayClick = (payload: any) => {
      if (convertTZ(payload.id) > convertTZ(new Date()) || site_id.value === '') {
        return
      }

      isInfoLoading.value = true
      day.value = payload.id
      store.dispatch('Dashboard/getProductStatus', {
        site_id: site_id.value,
        date: payload.id
      }).then(() => {
        isInfoLoading.value = false
      })
    }

    const getUser = async () => {
      const url = 'auth/user'
      const response = await axios.get(url)
      const user = response.data.data

      const {role} = toRefs(user)
      role.value = user.role.view
      if (role.value == 'Admin') {
        isAdmin.value = true
        getAllRetailers()
      } else {
        isAdmin.value = false
        getAllRetailerSites()
      }
    }

    onMounted(() => {
      getUser()
      const chart = c3.generate({
        bindto: '#chart',
        data: {
          // iris data from R
          json: [
            {name: 'www.site1.com', upload: 200, download: 200, total: 400},
            {name: 'www.site2.com', upload: 100, download: 300, total: 400},
            {name: 'www.site3.com', upload: 300, download: 200, total: 500},
            {name: 'www.site4.com', upload: 400, download: 100, total: 500}
          ],
          keys: {
            // x: 'name', // it's possible to specify 'x' when category axis
            value: ['upload', 'download']
          },
          type: 'pie',
          // onclick: function (d, i) { console.log('onclick', d, i) },
          // onmouseover: function (d, i) { console.log('onmouseover', d, i) },
          // onmouseout: function (d, i) { console.log('onmouseout', d, i) }
        }

      })
      const chart1 = c3.generate({
        bindto: '#chart1',
        data: {
          json: [
            {name: 'www.site1.com', upload: 200, download: 200, total: 400},
            {name: 'www.site2.com', upload: 100, download: 300, total: 400},
            {name: 'www.site3.com', upload: 300, download: 200, total: 500},
            {name: 'www.site4.com', upload: 400, download: 100, total: 500}
          ],
          keys: {
            // x: 'name', // it's possible to specify 'x' when category axis
            value: ['upload', 'download']
          },
          type: 'bar'
        },
        bar: {
          width: {
            ratio: 0.5 // this makes bar width 50% of length between ticks
          }
          // or
          //width: 100 // this makes bar width 100px
        }
      })
    })

    const getCalendarData = () => {
      if (site_id.value === '') {
        return
      }

      isCalendarLoading.value = true
      store.dispatch('Dashboard/getAllPostedInventory', {
        site_id: site_id.value,
        month: calendar.value.pages[0].month,
        year: calendar.value.pages[0].year
      }).then(() => {
        isCalendarLoading.value = false
      })
    }

    const getCalendarAttribute = () => {
      if (site_id.value === '') {
        return []
      }

      return store.getters['Dashboard/all_posted_inventory']
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const auth_user = computed(() => store.state.AuthUser.user)
    const attribute = computed(() => getCalendarAttribute())
    const productStatus = computed(() => store.getters['Dashboard/all_product_status'])
    const all_retailers = computed(() => store.getters['Option/all_retailers'])
    const all_retailer_sites = computed(() => store.getters['Option/all_retailer_sites'])
    const all_site_products = computed(() => store.getters['Option/all_site_products'])

    return {
      retailer_id,
      site_id,
      site_product_id,
      attribute,
      auth_user,
      all_retailers,
      all_retailer_sites,
      all_site_products,
      onChange,
      getCalendarData,
      calendar,
      handleDayClick,
      day,
      productStatus,
      columns,
      isCalendarLoading,
      isInfoLoading,
      isAdmin,
      filterOption
    }
  }
})
