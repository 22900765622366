
import { defineComponent, reactive, UnwrapRef } from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import axios from 'axios'
import Axios from '../../axios'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import Swal from 'sweetalert2'
import {baseURL} from "@/axios";

interface FormState {
  email: string;
  password: string;
}

export default defineComponent({
  setup () {
    const router = useRouter()
    const api = process.env.VUE_APP_BASEURL

    const userState: UnwrapRef<FormState> = reactive({
      email: '',
      password: '',
    })

    const handleFinish = async (values: FormState) => {
      await axios.post(`${baseURL}auth/login`, userState).then((res) => {
        window.localStorage.setItem('DWSR_TOKEN', res.data.data.token)
        Axios.defaults.headers['Authorization'] = `Bearer ${window.localStorage.getItem('DWSR_TOKEN')}`

        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false
        })
        router.push('/dashboard')
        // if (res.data.data.user.role.view == 'Admin') {
        //   router.push('/dashboard')
        //   //window.location.href = "/dashboard";
        // } else {
        //   // window.location.href = "/retailer";
        //   router.push('/retailer')
        // }
      }).catch((err) => {
        Modal.error({
          title: 'Login Failed',
          content: 'Your Email and Password is incorrect.',
        })
      })
    }

    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log(12121)
    }

    return {
      userState,
      handleFinish,
      handleFinishFailed
    }
  },
})
