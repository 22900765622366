
import { defineComponent, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import {VUE_APP_DWSR_ADMIN_EMAIL} from "@/axios";

export default defineComponent({
  props: {
    email: {
      type: String,
      default: '',
    }
  },
  setup (props) {
    const router = useRouter()
    const props_email = ref(props.email)
    const store = useStore()
    const isLoading = ref<boolean>(false)

    const tryAgain = (record: any) => {
      isLoading.value = true
      store
          .dispatch('AuthUser/accountRecovery', {email: props_email.value})
          .then(function (res: any) {
            isLoading.value = false
            Modal.success({
              title: 'Success',
              content: 'We\'ve sent another reset password email to ' + props_email.value + '.',
            })
          }).catch((error) => {
        isLoading.value = false

        if (error.response.status === 429) {
          const retrySeconds = error.response.headers['retry-after']
          const minutes = Math.floor(retrySeconds / 60)
          const seconds = retrySeconds - (minutes * 60)

          Modal.error({
            title: 'Reset limit reached.',
            content: 'You\'ve reached the maximum allowed password reset. Please try again in ' +
                (minutes > 0 ? (minutes + ' minute' + (minutes > 1 ? 's' : '') + ' and ') : '') +
                seconds + ' second' + (seconds > 1 ? 's' : '') + '.',
          })
        }
      })
    }

    const login = () => {
      router.push({name: 'Login'})
    }

    const supportEmail = computed(() => VUE_APP_DWSR_ADMIN_EMAIL)

    return {
      tryAgain,
      login,
      props_email,
      isLoading,
      supportEmail
    }
  },
})
